import Page404 from '@/views/404'
import AllRooms from '@/views/AllRooms'
import AllPackages from '@/views/AllPackages'
import Package from '@/views/Package'
import SingleRoom from '@/views/SingleRoom'
import Bundle from '@/views/Bundle'
import CustomerAccount from '@/views/CustomerAccount'

import Reservation from '@/views/Reservation'
import ReservationPayment from '@/views/ReservationPayment'
import ReservationCheckout from '@/views/Checkout'
import ReservationCheckoutConfirmation from '@/components/Reservation/ReservationCheckoutConfirmation'
import ReservationModify from '@/components/Reservation/ReservationModify'
import ReservationModifyConfirmation from '@/components/Reservation/ReservationModifyConfirmation'
import ReservationCancel from '@/components/Reservation/ReservationCancel'
import ReservationCancelConfirmation from '@/components/Reservation/ReservationCancelConfirmation'

import customerAccountPrivacyPolicy from '@/components/CustomerAccount/CustomerAccountPrivacyPolicy'
import customerAccountGeneralTerms from '@/components/CustomerAccount/CustomerAccountGeneralTerms'
import CheckoutAddServices from '@/components/Checkout/CheckoutAddServices'
import CheckoutGuestInfo from '@/components/Checkout/CheckoutGuestInfo'
import PaymentDetails from '@/components/Checkout/Payment/PaymentDetails'
import AfterPay from '@/components/Checkout/Afterpay/Afterpay'

import Config from '@/views/Config'
import CustomizationForm from '@/components/Customization/CustomizationForm'

export const routes = [
  {
    path: '/:hotelId',
    name: 'home',
    component: AllRooms
  },
  {
    path: '/:hotelId/packages',
    name: 'packages',
    component: AllPackages
  },
  {
    path: '/:hotelId/package/:packageId',
    name: 'package',
    component: Package
  },
  {
    path: '/:hotelId/room/:roomId',
    name: 'room',
    component: SingleRoom
  },
  {
    path: '/:hotelId/bundle/:channelId/:sessionId/:bundleId',
    name: 'bundle',
    component: Bundle,
    meta: { bodyClass: '_bundle' }
  },
  {
    path: '/:hotelId/customer-account',
    component: CustomerAccount,
    meta: { bodyClass: '_account' },
    children: [
      {
        path: 'privacy-policy',
        name: 'customer-account-privacy-policy',
        component: customerAccountPrivacyPolicy
      },
      {
        path: 'general-terms',
        name: 'customer-account-general-terms',
        component: customerAccountGeneralTerms
      }
    ]
  },
  {
    path: '/:hotelId/checkout',
    component: ReservationCheckout,
    meta: { bodyClass: '_checkout' },
    children: [
      {
        path: 'add-services/:roomId',
        name: 'CheckoutAddServices',
        component: CheckoutAddServices
      },
      {
        path: 'guest-info',
        name: 'CheckoutGuestInfo',
        component: CheckoutGuestInfo
      },
      {
        path: 'card-details',
        name: 'PaymentDetails',
        component: PaymentDetails
      },
      {
        path: 'afterpay',
        name: 'AfterPay',
        component: AfterPay,
        props: (route) => ({
          intent: route.query.i,
          result: route.query.r,
          // 'datatransTrxId' is added by Datatrans
          datatransTrxId: route.query.datatransTrxId,
          transactionId: route.query.t,
          transactionError: route.query.m
        })
      },
      {
        path: 'thank-you',
        name: 'ReservationCheckoutConfirmation',
        component: ReservationCheckoutConfirmation
      }
    ]
  },
  {
    path: '/:hotelId/cancel/:reservationId?',
    redirect: {
      name: 'ReservationCancel'
    }
  },
  {
    path: '/:hotelId/reservation',
    component: Reservation,
    name: 'Reservation',
    redirect: {
      name: 'home'
    },
    children: [
      {
        path: 'payment/:reservationId?',
        name: 'ReservationPayment',
        component: ReservationPayment,
        props: true
      },
      {
        path: 'cancel/:reservationId?/confirmation',
        name: 'ReservationCancelConfirmation',
        component: ReservationCancelConfirmation,
        props: true
      },
      {
        path: 'cancel/:reservationId?',
        name: 'ReservationCancel',
        component: ReservationCancel,
        props: (route) => ({
          reservationId: route.params.reservationId,
          channelId: route.query.channelId
        })
      },
      {
        path: ':reservationId/confirmation',
        name: 'ReservationModifyConfirmation',
        component: ReservationModifyConfirmation,
        props: true
      },
      {
        path: ':reservationId',
        name: 'ReservationModify',
        component: ReservationModify,
        props: (route) => ({
          reservationId: route.params.reservationId,
          token: route.params.token
        })
      }
    ]
  },
  {
    path: '/:hotelId/config',
    name: 'config',
    component: Config,
    meta: { bodyClass: '_config' },
    children: [
      {
        path: 'customization',
        alias: 'customization-form',
        name: 'customization-form',
        component: CustomizationForm
      }
    ]
  },
  { path: '/:hotelId/*', component: Page404 }
]
