var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"rate-plan-item"},[_c('v-row',{staticClass:"rate-row d-flex ma-0",attrs:{"sm":"row","xs":"column"}},[_c('v-col',{staticClass:"pa-4",attrs:{"lg":"6","md":"6","sm":"6"}},[_c('div',{staticClass:"d-flex"},[_c('div',{staticClass:"pr-5 flex-grow-1"},[_c('v-card-title',{staticClass:"text-body-2 font-weight-bold pa-0 mb-2",staticStyle:{"word-break":"break-word"}},[_vm._v("\n            "+_vm._s(_vm.item.rate?.ratePlanName)+"\n          ")]),_vm._v(" "),_c('pre',{staticClass:"text-body-2 text-justify",staticStyle:{"line-height":"1rem","white-space":"pre-wrap"}},[_vm._v(_vm._s(_setup.description))]),_vm._v(" "),(_setup.description.length > _setup.RATE_DESC_MAX_LEN)?_c('v-btn',{staticClass:"text-none text-decoration-underline px-1",attrs:{"text":"","ripple":false},on:{"click":function($event){_setup.state.showFullDescription = !_setup.state.showFullDescription}}},[_vm._v("\n            "+_vm._s(_setup.state.showFullDescription
                ? _setup.t('AvailabilityItem#Button Show less')
                : _setup.t('AvailabilityItem#Button Show more'))+"\n          ")]):_vm._e()],1),_vm._v(" "),_c('v-divider',{attrs:{"vertical":""}})],1)]),_vm._v(" "),_c('v-col',{staticClass:"pl-0 d-flex",attrs:{"lg":"6"}},[_c('v-row',{staticClass:"ma-0"},[_c('v-col',{staticClass:"d-flex align-center pa-0"},[_c('div',{staticClass:"flex-column flex-wrap"},[_c(_setup.CancellationConditionBadge,{attrs:{"condition":_vm.item.cancellationConditions}}),_vm._v(" "),_c(_setup.GuaranteeTypeBadge,{attrs:{"type":_vm.item.rate?.guaranteeType}})],1)]),_vm._v(" "),_c('v-col',{staticClass:"py-0 px-2 d-flex align-center justify-end",attrs:{"lg":"6"}},[_c('div',{staticClass:"d-flex flex-column align-end text-no-wrap mr-3"},[_c('div',{class:[
                'font-weight-bold',
                'text-body-2',
                { 'error--text': _vm.item.discount > 0 }
              ]},[_vm._v("\n              "+_vm._s(_setup.ratePlanPrice(_vm.item))+"\n            ")]),_vm._v(" "),(_vm.item.discount > 0)?_c('div',{staticClass:"text--disabled text-caption"},[_c('span',{staticClass:"text-decoration-line-through"},[_vm._v("\n                "+_vm._s(_setup.formatPrice(_vm.item.totalPrice / _vm.item.nbNights, {
                    currency: _vm.item.currency
                  }))+"\n              ")])]):_vm._e()]),_vm._v(" "),_c('v-btn',{staticClass:"text-capitalize",attrs:{"color":"primary","disabled":_setup.isBtnDisabled(_vm.item.productId),"ripple":false,"tile":""},on:{"click":function($event){return _setup.bookRatePlan(_vm.item.productId)}}},[_vm._v("\n            "+_vm._s(_setup.t('AvailabilityItem#Button Book'))+"\n          ")])],1)],1)],1)],1),_vm._v(" "),_c('v-divider',{staticClass:"mx-3"})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }