import { render, staticRenderFns } from "./ReservationForm.vue?vue&type=template&id=cbcbfc7e&lang=pug"
import script from "./ReservationForm.vue?vue&type=script&setup=true&lang=ts"
export * from "./ReservationForm.vue?vue&type=script&setup=true&lang=ts"
import style0 from "./ReservationForm.vue?vue&type=style&index=0&id=cbcbfc7e&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/.pnpm/vue-loader@15.11.1_css-loader@6.8.1_lodash@4.17.21_prettier@3.1.1_pug@3.0.2_webpack@5.89.0/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports