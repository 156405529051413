import {
  COLOR_SCHEME,
  ROOM_AMENITY_TYPE,
  LANGUAGES,
  CURRENCIES
} from '../constants'
import { CHANNEL_ID_DEFAULT } from '../system/constants/channels'

export default {
  loading: false,
  language: '',
  languages: LANGUAGES,
  currency: '', // user preferred currency
  hotelCurrency: '', // hotel currency
  invoicingCurrency: '', // HS invoicing currency
  currencies: CURRENCIES,
  subscription: 'none',
  searchParams: {},
  tenantId: '',
  privacyPolicy: '',
  hasPromotions: false,
  useOffers: true,
  addressRequired: false,
  allowThanks: false,
  reservationLoading: false,
  cancellationLoading: false,
  inlineBookRequestError: false,
  allowCancellationConfirm: false,
  orderCancelledInfo: {},
  emptyList: false,
  descriptiveInfo: {},
  descriptiveInfoLoaded: false,
  colorScheme: COLOR_SCHEME,
  customization: COLOR_SCHEME.light,
  allowPreferredColorScheme: false,
  resultsListDisplay: '',
  customizationFormSending: false,
  guestsBounds: {
    adults: {
      min: 1,
      max: 8,
      default: 1
    },
    children: {
      min: 0,
      max: 4,
      default: 0
    },
    infants: {
      min: 0,
      max: 4,
      default: 0
    }
  },
  defaultSorting: 'popularity',
  hotelHomepage: '',
  sorterName: '',
  availabilitiesLoaded: false,
  configLoaded: false,
  productsLoaded: false,
  hotelPackagesLoaded: false,
  products: [],
  productsAvailable: [],
  hotelPackages: [],
  hotelPackagesAvailable: [],
  servicesInfo: [],
  servicesLoaded: false,
  calendarData: {
    specialDays: {},
    disabledDays: []
  },
  orderInfo: {
    product: {},
    datesDiff: 0,
    penalties: '',
    upgrades: [],
    upsell: [],
    userName: '',
    reservationId: '',
    createDateTime: ''
  },
  currentRoomStay: {
    currencyCode: '',
    reference: '',
    guestRoomId: '',
    ratePlanId: '',
    startDate: '',
    endDate: '',
    promotionCode: '',
    totalPrice: 0,
    totalDiscount: 0,
    nbAdults: 1,
    nbChildren: 0,
    nbInfants: 0,
    services: []
  },
  roomsBeforeRender: [],
  rooms: [],
  serverRoomAvailabilities: {},
  roomFilters: {
    titles: [],
    amenities: [],
    priceRange: []
  },
  defaultPriceRange: [],
  logged: false,
  filteredRooms: [],
  user: {},
  bundles: [],
  services: [],
  trackers: null,
  channelId: CHANNEL_ID_DEFAULT,
  channelParameters: {},
  paymentType: null,
  roomFacilitiesCodes: ROOM_AMENITY_TYPE
}
