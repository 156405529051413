import { watch } from "vue";
import { useCssVar, useStorage } from '@vueuse/core';
import { useVuetify } from './useVuetify';
import { lightenColor, opaqueColor } from '@/app/system/helper';
import store from '../store';

const cssVars = {
  bg: '--hs-body-bg',
  bgImg: '--hs-body-bg-img',
  btn: '--hs-color-btn',
  btnBg: '--hs-color-btn-bg',
  btnBgLight: '--hs-color-btn-bg-light',
  btnBgOpaque: '--hs-color-btn-bg-opaque',
  btnFont: '--hs-font-family-btn',
  titleFont: '--hs-font-family-title',
  titleFontSize: '--hs-font-size-title',
  btnFontSize: '--hs-font-size-btn',
  link: '--hs-color-link',
  primary: '--hs-color-primary',
  primaryLight: '--hs-color-primary-light',
  primaryFont: '--hs-font-family-primary',
  primaryFontSize: '--hs-font-size-primary',
  text: '--hs-text-color',
  textOpaque: '--hs-text-color-opaque',
  borderColor: '--hs-border-color',
};

const getPrimaryFontSize = (size = "medium") => ({
  "small": "0.75rem",
  "large": "1.1rem",
})[size] || "0.8rem";

const getTitleFontSize = (size = "medium") => ({
  "small": "1.6rem",
  "large": "1.75rem",
})[size] || "1.4rem";

const vars = Object.fromEntries(Object.entries(cssVars).map(([key, value]) => [key, useCssVar(value)]));

export default function useTheme() {
  const vuetify = useVuetify();
  const theme = useStorage('SB4.theme');

  watch(
    () => store.state.customization,
    (newVal) => {
    vars.btn.value = newVal.colorBtn.hex;
    vars.btnBg.value = newVal.colorBtnBg.hex;
    vars.btnFont.value = newVal.fontFamilyBtn;
    vars.titleFont.value = newVal.fontFamilyTitle;
    vars.titleFontSize.value = getTitleFontSize(newVal.fontSizeTitle);
    vars.btnFontSize.value = getPrimaryFontSize(newVal.fontSizeBtn);
    vars.link.value = newVal.colorLink.hex;
    vars.primary.value = newVal.colorPrimary.hex;
    vars.primaryFont.value = newVal.fontFamilyPrimary;
    vars.primaryFontSize.value = getPrimaryFontSize(newVal.fontSizePrimary);
    vars.text.value = newVal.colorText.hex;
    vars.bg.value =  newVal.colorBg.hex;
    vars.borderColor.value = opaqueColor(newVal.colorPrimary.hex, 0.25);
    vars.primaryLight.value = lightenColor(newVal.colorPrimary.hex, 0.88);
    vars.btnBgLight.value = lightenColor(newVal.colorBtnBg.hex, 0.88);
    vars.btnBgOpaque.value = opaqueColor(newVal.colorBtnBg.hex, 0.8);
    vars.textOpaque.value = opaqueColor(newVal.colorText.hex, 0.5);
    vuetify.theme.themes.light.primary = newVal.colorBtnBg.hex;
    vuetify.theme.themes.light.secondary = newVal.colorPrimary.hex;
    vuetify.theme.themes.dark.primary = newVal.colorBtnBg.hex;
    vuetify.theme.isDark = theme.value === 'dark';
    if (newVal.bgUrl) {
      vars.bgImg.value = `transparent url('${newVal.bgUrl}') no-repeat center top`;
    }
  },
  {
    deep: true,
  })
}
